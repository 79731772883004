// =============================================================================
// Block
// =============================================================================
.hoi-codification {
    position: relative;
    white-space: pre-wrap;
}

// =============================================================================
// Elements
// =============================================================================
.hoi-codification__word {
    white-space: nowrap;
}

.hoi-codification__character {
    display: inline-block;
    position: relative;
}

.hoi-codification__result {
    opacity: 0;
}

.hoi-codification__glyph {
    position: absolute;
    left: 0;
    top: 0;
}

// =============================================================================
// Modifiers
// =============================================================================
