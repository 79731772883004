@use 'sass:math';
@use '../../variables' as vars;

$title-font-size: vars.to-vh(188, 1920);
$title-line-height: $title-font-size * 0.9;
 // this offset compensates the vertical offset due to different font size and line height
$title-vertical-offset: math.max($title-font-size - $title-line-height, 0);

$title-padding: vars.to-vh(66, 1920);

@mixin main {
    height: 100%;
    position: relative;
    background: white;

    & > * {
        position: absolute;
        top: 0;
    }

    .text_container {
        @include text_container;
    }
}

@mixin text_container {
    @include vars.hoi-font;

    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: $title-font-size;
    line-height: $title-line-height;

    > * {
        width: 100%;
        padding: $title-padding;

        > * {
            margin-top: calc($title-vertical-offset * var(--hoi-font-vertical-correction));
        }
    }

}
