/*
 This is the animation doing the solarization.
 The percentages can be adjusted to make the actual swipe
 effect take different amounts of time.
 The slide-in happens between the first 2 steps (0% - 10%)
 The slide-out happens between the 3rd and 4th steps (60% -70%)
 Between 10% and 60% is when the text is solarized
 Between 70% and 0% is when the text is normal

 If needed these can be separated out into different triggerable steps
 */
@keyframes slide-in-out {
	0% {
		visibility: visible;
		clip-path: inset(0 100% 0 0);
	}
	5% {
		visibility: visible;
		clip-path: inset(0);
	}
	60% {
		visibility: visible;
		clip-path: inset(0);
	}
	70% {
		visibility: visible;
		clip-path: inset(0 0 0 100%);
	}
	100% {
		visibility: hidden;
		clip-path: inset(0 0 0 100%);
	}
}
/*
 This is the animation moving the whole screen right-to-left
 */
@keyframes bannermove {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-200%, 0);
	}
}

.scroll-ticker-pixelmap {
	overflow: hidden;
	position: absolute;
}


.scroll-ticker-wrapper, .scroll-ticker-wrapper-inner {
	position: absolute;
	height: 100%;
	overflow: hidden;
	background: var(--primary-color, initial);
}

.scroll-ticker-wrapper-inner.invert {
	color: var(--primary-color, white);
}

.scroll-ticker-container {
	background: inherit;
	color: inherit;
}

.scroll-ticker-wrapper-inner.invert .icon path {
	fill: white !important;
}
.scroll-ticker-wrapper-inner.invert .icon .solarization path {
	fill: black !important;
}


.scroll-ticker-container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.scroll-ticker-container:nth-child(2) {
	top: 100%;
}

.scroll-ticker-row {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	left: 100%;
	display: grid;
	grid-auto-flow: column;
	justify-content: start;
	align-items: center;
	animation: bannermove var(--time) linear infinite;
}

.sizer-wrapper {
	height: 100%;
}

.sizer-wrapper .scroll-ticker-row {
	width: auto;
}

.scroll-ticker-row:first-child {
	animation-delay: calc(var(--time) * -0.5);
}

.text, .icon {
	position: relative;
	box-sizing: border-box;
	white-space: nowrap;
	text-transform: uppercase;
}


.solarization {
	--horizontal-padding: 20px;
	position: absolute;
	color: white;
	visibility: hidden;
	left: calc(var(--horizontal-padding) * -1);
	top: 0;
	padding: 0 var(--horizontal-padding);
	height: 100%;
	background: linear-gradient(90deg, #f09336 0%, #ea3525 100%);
	animation: slide-in-out var(--solarization-time) linear infinite;
	animation-delay: var(--delay);
	& path {
		fill: white;
	}
}

.text .TickerText {
	height: 100%;
}


.paused {
	-webkit-animation-play-state: paused;
	-moz-animation-play-state: paused;
	-o-animation-play-state: paused;
	animation-play-state: paused;
}
