@use 'variables' as vars;
@use 'fonts' as *;

* {
    --hoi-background-red: #{vars.$red-color};
}

.hoi-font {
    @include vars.hoi-font;
}
