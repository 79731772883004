.helvetica-neue {
  font-family: "HelveticaNeue";

  * {
    font-family: "HelveticaNeue";
  }
}

.helvetica-neue-medium {
  font-family: "helvetica-neue-medium";

  * {
    font-family: "helvetica-neue-medium";
  }
}

.futura-condensed {
  font-family: "futura";

  * {
    font-family: "futura";
  }
}

.brut-grotesque {
  font-family: "Brut Grotesque";

  * {
    font-family: "Brut Grotesque";
  }
}

.nike-helvetica {
  font-family: "NikeHelvetica";

  * {
    font-family: "NikeHelvetica";
  }
}

.helvetica-neue-hoi {
  font-family: 'helvetica-neue-hoi';

  * {
    font-family: 'helvetica-neue-hoi';
  }
}