.ColorBlock {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // background: rgba(255, 255, 255, 0.3);


    z-index: 5;

    &.inset-true {
        border: 0.37037037vw solid transparent;
        box-sizing: border-box;
    }

    // :after {
    //     border: 0.37037037vw solid #fff;
    //     box-sizing: border-box;
    //     content: ' ';
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //     width: 100%;
    //     z-index: 9999;
    // }

    video {
        // position: absolute;
        // z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    img {
        // position: absolute;
        // z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}
