* {
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'helvetica-neue-hoi-ehq';
  src: url('../assets/fonts/NeueHelveticaHOI_EHQ.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
