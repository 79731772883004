* {
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'jordan-text';
  src: url('../assets/fonts/jordan/JordanNHGText-75Bold.woff2') format('woff2'),
  url('../assets/fonts/jordan/JordanNHGText-75Bold.woff') format('woff'),
  url('../assets/fonts/jordan/JordanNHGText-75Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'jordan-display';
  src: url('../assets/fonts/jordan/JordanNHGDisp-75Bold.woff2') format('woff2'),
  url('../assets/fonts/jordan/JordanNHGDisp-75Bold.woff') format('woff'),
  url('../assets/fonts/jordan/JordanNHGDisp-75Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'jordan-text';
  src: url('../assets/fonts/jordan/JordanNHGText-55Roman.woff2') format('woff2'),
  url('../assets/fonts/jordan/JordanNHGText-55Roman.woff') format('woff'),
  url('../assets/fonts/jordan/JordanNHGText-55Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'jordan-display';
  src: url('../assets/fonts/jordan/JordanNHGDisp-55Roman.woff2') format('woff2'),
  url('../assets/fonts/jordan/JordanNHGDisp-55Roman.woff') format('woff'),
  url('../assets/fonts/jordan/JordanNHGDisp-55Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'jordan-display';
  src: url('../assets/fonts/jordan/JordanNHGDisp-65Medium.woff2') format('woff2'),
  url('../assets/fonts/jordan/JordanNHGDisp-65Medium.woff') format('woff'),
  url('../assets/fonts/jordan/JordanNHGDisp-65Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'jordan-text';
  src: url('../assets/fonts/jordan/JordanNHGText-65Medium.woff2') format('woff2'),
  url('../assets/fonts/jordan/JordanNHGText-65Medium.woff') format('woff'),
  url('../assets/fonts/jordan/JordanNHGText-65Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'pp-ultralight';
  src: url('../assets/fonts/pp/PPEditorialNew-Ultralight/PPEditorialNew-Ultralight.woff2') format('woff2'),
  url('../assets/fonts/pp/PPEditorialNew-Ultralight/PPEditorialNew-Ultralight.woff') format('woff'),
  url('../assets/fonts/pp/PPEditorialNew-Ultralight/PPEditorialNew-Ultralight.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'pp-ultralight';
  src: url('../assets/fonts/pp/PPEditorialNew-UltralightItalic/PPEditorialNew-UltralightItalic.woff2') format('woff2'),
  url('../assets/fonts/pp/PPEditorialNew-UltralightItalic/PPEditorialNew-UltralightItalic.woff') format('woff'),
  url('../assets/fonts/pp/PPEditorialNew-UltralightItalic/PPEditorialNew-UltralightItalic.ttf') format('truetype');
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'pp-regular';
  src: url('../assets/fonts/pp/PPEditorialNew-Regular/PPEditorialNew-Regular.woff2') format('woff2'),
  url('../assets/fonts/pp/PPEditorialNew-Regular/PPEditorialNew-Regular.woff') format('woff'),
  url('../assets/fonts/pp/PPEditorialNew-Regular/PPEditorialNew-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'monospace-typewriter';
  src: url('../assets/fonts/monospace-typewriter/MonospaceTypewriter.woff2') format('woff2'),
  url('../assets/fonts/monospace-typewriter/MonospaceTypewriter.woff') format('woff'),
  url('../assets/fonts/monospace-typewriter/MonospaceTypewriter.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Brut Grotesque';
  src: url('../assets/fonts/brut-grotesque/BrutGrotesqueWEBL-Bold.woff2') format('woff2'),
  url('../assets/fonts/brut-grotesque/BrutGrotesqueWEBL-Bold.woff') format('woff'),
  url('../assets/fonts/brut-grotesque/BrutGrotesqueWEBL-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans SC';
  src: url('../assets/fonts/noto-sans-sc/NotoSansCJKsc-Black.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Noto Sans SC';
  src: url('../assets/fonts/noto-sans-sc/NotoSansSC-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
