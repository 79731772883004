.NSILSchedule {
  background: black;
  height: 100vh;
  width: 100vw;

  .ticker {
    background: unset;
    color: unset;
    font-size: unset;
    line-height: unset;
  }

  &.quiet,
  &.closed,
  &.reserve_now {
    background: #fff000;
  }
  &.do_not_enter,
  &.booked {
    background: #ff0000;
  }

  &-video {
    position: fixed;
    z-index: 1;
    opacity: 0.7;
    mix-blend-mode: multiply;
  }

  &-ticker {
    position: fixed;
    box-sizing: border-box;
    height: 3.22916667vh;
    background: #000;
    color: #fff;
    z-index: 997;
    width: 56.25vh;
    font-size: 1.45833333vh;
    line-height: 3.22916667vh;
    span {
      display: inline-block;
      padding-right: 2.08333333vh;
    }
  }

  &-bottom-ticker {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    height: 3.22916667vh;
    background: #000;
    color: #fff;
    z-index: 997;
    width: 56.25vh;
    font-size: 1.45833333vh;
    line-height: 3.22916667vh;
    span {
      display: inline-block;
      padding-right: 2.08333333vh;
    }
  }

  &-header {
    position: fixed;
    box-sizing: border-box;
    padding: 2.08333333vh;
    top: 3.22916667vh;
    left: 0;
    z-index: 998;

    img {
      height: 7.5vh;
      filter: invert(1);
    }
  }

  &-header-right {
    position: fixed;
    box-sizing: border-box;
    padding: 2.08333333vh;
    top: 3.22916667vh;
    right: 0;
    z-index: 998;

    h2 {
      text-align: right;
      color: #000;
      font-family: Helvetica;
      font-size: 3vh;
      margin-bottom: calc(2.08333333vh / 2);
      span {
        display: block;
      }
    }
  }

  &-content {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 56.25vh;
    box-sizing: border-box;
    padding: 2.08333333vh;

    * {
      color: #000;
    }

    h1 {
      font-family: FUTURA;
      font-size: 10.9375vh;
      line-height: 0.9em;
      max-width: 90%;
      text-transform: uppercase;
      span {
        display: block;
      }
      .small-font {
        font-size: 8vh;
        line-height: 1em;
      }
    }

    h1.quiet,
    h1.closed,
    h1.reserve_now {
      font-size: 8vh;
      line-height: 0.9em;
    }

    h2 {
      font-family: Helvetica;
      font-size: 2vh;
      margin-bottom: calc(2.08333333vh / 2);
      span {
        display: block;
      }
    }

    h3 {
      font-family: Helvetica;
      font-size: 3vh;
      margin-bottom: calc(2.08333333vh / 2);
      span {
        display: block;
      }
    }

    span.email {
      font-family: FUTURA;
      font-size: 3.2vh;
      max-width: 90%;
    }

    &-footer {
      font-family: Helvetica;
      font-weight: bold;
      border-top: 8px solid #000;
      padding-top: 2.08333333vh;
      margin-top: 2.08333333vh;
      font-size: 1.95vh;
    }

    &-footer.quiet,
    &-footer.closed,
    &-footer.reserve_now {
      display: none;
    }
  }

  &.no-event {
    &.open,
    &.available {
      background: #73ea78;
    }
  }

  &.current-event {
    &.open,
    &.available {
      background: #000;

      & .NSILSchedule-video {
        filter: invert(1);
        mix-blend-mode: normal;
      }

      & .NSILSchedule-ticker {
        background: #73ea78;
        color: #000;
      }

      & .NSILSchedule-header {
        img {
          filter: invert(0);
        }
      }

      & .NSILSchedule-header-right {
        h2 {
          color: #fff;
        }
      }

      & .NSILSchedule-content {
        * {
          color: #fff;
        }
      }

      & .NSILSchedule-content-footer {
        border-top: 8px solid #fff;
      }
    }
  }
}
